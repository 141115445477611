import { FC } from 'react';
import { Box } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import { Text } from 'modules/theme/components/text/Text';
import CloseIcon from 'modules/theme/components/icons/basic/Close.svg';
import {
    AppBar
} from 'components/advertisementsFilter/components/common/mobileFilter/partials/filterDialog/partials/common/appBar/AppBar';

interface IHeader {
    isFilterOpened: boolean;
    toggleFilter: (flag: boolean) => void;
}

export const Header: FC<IHeader> = ({
    toggleFilter,
    isFilterOpened,
}) => {

    return <AppBar
        mb={2}
        content={(
            <Box
                width='100%'
                display='flex'
                alignItems='center'
                position='relative'
                justifyContent='space-between'
            >
                <Svg
                    icon
                    width={24}
                    component={CloseIcon}
                    onIconClick={() => toggleFilter(!isFilterOpened)}
                />

                <Box
                    left='50%'
                    position='absolute'
                    sx={{ transform: 'translate(-50%, 0)' }}
                >
                    <Text variant='body2' semibold>
                        Hľadanie
                    </Text>
                </Box>
            </Box>
        )}
    />;
};