import { FC } from 'react';
import { Box } from '@mui/material';
import SelectDownIcon from 'modules/theme/components/icons/basic/SelectDown.svg';
import { Svg } from 'modules/theme/components/svg/Svg';
import { Text } from 'modules/theme/components/text/Text';

interface IBaseBox {
    title: string;
    text?: string;
    placeholder: string;
    onBoxToggle: () => void;
}

export const BaseBox: FC<IBaseBox> = ({ title, text, placeholder, onBoxToggle }) => {

    return <Box
        gap={3}
        paddingX={3}
        paddingY={2}
        display='flex'
        borderRadius='16px'
        alignItems='center'
        onClick={onBoxToggle}
        justifyContent='space-between'
        boxShadow='0px 2px 4px 0px rgba(0, 0, 0, 0.08)'
        bgcolor={(theme) => theme.palette.backgroundPrimary.main}
    >
        <Text
            variant='body2'
            color={(palette) => palette.labelSecondary}
        >
            {title}
        </Text>

        <Box
            gap={1}
            display='flex'
            overflow='hidden'
            whiteSpace='nowrap'
            textOverflow='ellipsis'
            alignItems='baseline'
        >
            <Text
                noWrap
                semibold
                variant='body2'
                color={(palette) => text
                    ? palette.labelPrimary
                    : palette.labelSecondary
                }
            >
                {text || placeholder}
            </Text>
            <Svg
                icon
                width={14}
                sx={{ paddingTop: '5px'}}
                component={SelectDownIcon}
                color={text ? 'primary' : 'secondary'}
            />
        </Box>
    </Box>;
};