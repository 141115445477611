import { FC } from 'react';
import { Box, Container } from '@mui/material';
import {
    Transaction
} from 'components/advertisementsFilter/components/common/mobileFilter/partials/filterDialog/partials/content/partials/transaction/Transaction';
import {
    Location
} from 'components/advertisementsFilter/components/common/mobileFilter/partials/filterDialog/partials/content/partials/location/Location';
import {
    Categories
} from 'components/advertisementsFilter/components/common/mobileFilter/partials/filterDialog/partials/content/partials/categories/Categories';
import {
    ITransaction
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/transaction/ITransaction';
import {
    ICategories
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/category/ICategories';
import { useMobileFullboxes } from 'components/advertisementsFilter/hooks/mobileFilter/useMobileFullboxes';
import {
    IAutocompleteOption
} from 'components/advertisementsFilter/interfaces/location/option/IAutoCompleteOption';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';

interface IContent {
    locationQuery: string;
    selectedLocation: ILocation;
    categoryOptions: ICategories;
    selectedCategoryIds: number[];
    selectedCategoryNames: string;
    selectedSubcategoryNames: string;
    selectedTransaction: ITransaction;
    transactionOptions: ITransaction[];
    locationOptionsInitial: ILocation[];
    onSelectCategory: (value: number) => void;
    onDeselectCategory: (value: number) => void;
    onChangeTransaction: (value: number) => void;
    onChangeLocationQuery: (value: string) => void;
    onChangeLocation: (location: ILocation) => void;
    autocompleteLocationOptions: IAutocompleteOption[];
}

export const Content: FC<IContent> = ({
    locationQuery,
    categoryOptions,
    onSelectCategory,
    onChangeLocation,
    selectedLocation,
    onDeselectCategory,
    transactionOptions,
    onChangeTransaction,
    selectedCategoryIds,
    selectedTransaction,
    onChangeLocationQuery,
    selectedCategoryNames,
    locationOptionsInitial,
    selectedSubcategoryNames,
    autocompleteLocationOptions,
}) => {
    const {
        isLocationFullboxOpened,
        isCategoryFullboxOpened,
        isTransactionFullboxOpened,
        onChangeCategoryFullboxOpened,
        onChangeLocationFullboxOpened,
        onChangeTransactionFullboxOpened,
    } = useMobileFullboxes();

    return <Container sx={{
        paddingTop: '5px',
        paddingBottom: '100px',
        overflowY: 'scroll',
        overflowX: 'hidden'
    }}>
        <Box mb={1.5}>
            <Location
                locationQuery={locationQuery}
                selectedLocation={selectedLocation}
                onChangeLocation={(location) => {
                    onChangeLocation(location);

                    onChangeLocationFullboxOpened(true);

                    if (location) {
                        onChangeTransactionFullboxOpened(true);
                    } else {
                        onChangeTransactionFullboxOpened(false);
                    }
                }}
                optionsInitial={locationOptionsInitial}
                isFullboxOpened={isLocationFullboxOpened}
                onChangeLocationQuery={onChangeLocationQuery}
                onChangeFullboxOpened={() => {
                    const stateFlag = isLocationFullboxOpened ? false : true;

                    onChangeLocationFullboxOpened(stateFlag);

                    if (stateFlag) {
                        onChangeCategoryFullboxOpened(false);
                        onChangeTransactionFullboxOpened(false);
                    }
                }}
                autocompleteLocationOptions={autocompleteLocationOptions}
            />
        </Box>

        <Box mb={1.5}>
            <Transaction
                transactions={transactionOptions}
                selectedTransaction={selectedTransaction}
                onChangeTransaction={(transaction) => {
                    onChangeTransaction(transaction);

                    onChangeTransactionFullboxOpened(false);

                    if (transaction) {
                        onChangeCategoryFullboxOpened(true);
                    } else {
                        onChangeCategoryFullboxOpened(false);
                    }
                }}
                isFullboxOpened={isTransactionFullboxOpened}
                onChangeFullboxOpened={() => {
                    const stateFlag = isTransactionFullboxOpened ? false : true;

                    onChangeTransactionFullboxOpened(stateFlag);

                    if (stateFlag) {
                        onChangeLocationFullboxOpened(false);
                        onChangeCategoryFullboxOpened(false);
                    }
                }}
            />
        </Box>

        <Box mb={1.5}>
            <Categories
                categoryOptions={categoryOptions}
                onSelectCategory={onSelectCategory}
                onDeselectCategory={onDeselectCategory}
                selectedCategoryIds={selectedCategoryIds}
                isFullboxOpened={isCategoryFullboxOpened}
                selectedCategoryNames={selectedCategoryNames}
                selectedSubcategoryNames={selectedSubcategoryNames}
                onChangeFullboxOpened={() => {
                    const stateFlag = isCategoryFullboxOpened ? false : true;

                    onChangeCategoryFullboxOpened(stateFlag);

                    if (stateFlag) {
                        onChangeLocationFullboxOpened(false);
                        onChangeTransactionFullboxOpened(false);
                    }
                }}
            />
        </Box>
    </Container>;
};