import { FC } from 'react';
import {
    ITransaction as ITransactionOption
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/transaction/ITransaction';
import {
    CompleteBox
} from 'components/advertisementsFilter/components/common/common/transaction/partials/completeBox/CompleteBox';
import {
    BaseBox
} from 'components/advertisementsFilter/components/common/mobileFilter/partials/filterDialog/partials/content/partials/boxes/baseBox/BaseBox';
import {
    FullBox
} from 'components/advertisementsFilter/components/common/mobileFilter/partials/filterDialog/partials/content/partials/boxes/fullBox/FullBox';
import { useTransactionOptions } from 'components/advertisementsFilter/hooks/transaction/options/useTransactionOptions';
interface ITransaction {
    isFullboxOpened: boolean;
    transactions: ITransactionOption[];
    selectedTransaction: ITransactionOption;
    onChangeTransaction: (value: number) => void;
    onChangeFullboxOpened: (flag: boolean) => void;
}

export const Transaction: FC<ITransaction> = ({
    transactions,
    isFullboxOpened,
    selectedTransaction,
    onChangeTransaction,
    onChangeFullboxOpened,
}) => {
    const {
        selectedOptionTitle
    } = useTransactionOptions(transactions, selectedTransaction);

    return <>
        {isFullboxOpened
            ? <FullBox
                title='Ponuka'
                onBoxToggle={() => onChangeFullboxOpened(!isFullboxOpened)}
                content={(
                    <CompleteBox
                        options={transactions}
                        transaction={selectedTransaction && selectedTransaction.id}
                        onTransactionChange={onChangeTransaction}
                    />
                )}
            />
            : <BaseBox
                title='Ponuka'
                text={selectedOptionTitle}
                placeholder='Celá ponuka'
                onBoxToggle={() => onChangeFullboxOpened(!isFullboxOpened)}
            />
        }
    </>;
};