import { useCallback, useEffect, useState } from 'react';
import { useMediaQuery, Theme } from '@mui/material';

interface IReturn {
    isLocationFullboxOpened: boolean;
    isCategoryFullboxOpened: boolean;
    isTransactionFullboxOpened: boolean;
    onChangeLocationFullboxOpened: (flag: boolean) => void;
    onChangeCategoryFullboxOpened: (flag: boolean) => void;
    onChangeTransactionFullboxOpened: (flag: boolean) => void;
}

export const useMobileFullboxes = (): IReturn => {
    const [isTransactionFullboxOpened, setIsTransactionFullboxOpened] = useState(false);
    const [isLocationFullboxOpened, setIsLocationFullboxOpened] = useState(false);
    const [isCategoryFullboxOpened, setIsCategoryFullboxOpened] = useState(false);
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );

    const onChangeTransactionFullboxOpened = useCallback((flag: boolean) => {
        setIsTransactionFullboxOpened(flag);
    }, []);

    const onChangeLocationFullboxOpened = useCallback((flag: boolean) => {
        setIsLocationFullboxOpened(flag);
    }, []);

    const onChangeCategoryFullboxOpened = useCallback((flag: boolean) => {
        setIsCategoryFullboxOpened(flag);
    }, []);

    useEffect(() => {
        if (isMobile) onChangeLocationFullboxOpened(true);
    }, [isMobile, onChangeLocationFullboxOpened]);

    return {
        isLocationFullboxOpened,
        isCategoryFullboxOpened,
        isTransactionFullboxOpened,
        onChangeLocationFullboxOpened,
        onChangeCategoryFullboxOpened,
        onChangeTransactionFullboxOpened,
    };
};
