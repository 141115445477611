import { FC, ReactNode } from 'react';
import { AppBar as MuiAppBar, Toolbar } from '@mui/material';

interface IAppBar {
    mb?: number;
    content: ReactNode;
}

export const AppBar: FC<IAppBar> = ({ content, mb }) => {

    return <MuiAppBar
        variant='header'
        position='static'
        sx={(theme) => ({
            height: 'auto',
            marginBottom: mb,
            color: theme.palette.labelPrimary.main,
            bgcolor: theme.palette.backgroundPrimary.main,
            boxShadow: ' 0px 2px 4px 0px rgba(0, 0, 0, 0.08)',
        })}
    >
        <Toolbar sx={{
            height: 48,
            display: 'flex',
            alignItems: 'center',
            minHeight: '0px!important',
            justifyContent: 'space-between'
        }}>
            {content}
        </Toolbar>
    </MuiAppBar>;
};