import { FC } from 'react';
import {
    BaseBox
} from 'components/advertisementsFilter/components/common/mobileFilter/partials/filterDialog/partials/content/partials/boxes/baseBox/BaseBox';
import {
    FullBox
} from 'components/advertisementsFilter/components/common/mobileFilter/partials/filterDialog/partials/content/partials/boxes/fullBox/FullBox';
import {
    CompleteBox
} from 'components/advertisementsFilter/components/common/common/category/partials/completeBox/CompleteBox';
import {
    ICategories as ICategoriesOptions
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/category/ICategories';

interface ICategories {
    isFullboxOpened: boolean;
    selectedCategoryIds: number[];
    selectedCategoryNames: string;
    selectedSubcategoryNames: string;
    categoryOptions: ICategoriesOptions;
    onSelectCategory: (value: number) => void;
    onDeselectCategory: (value: number) => void;
    onChangeFullboxOpened: (flag: boolean) => void;
}

export const Categories: FC<ICategories> = ({
    isFullboxOpened,
    categoryOptions,
    onSelectCategory,
    onDeselectCategory,
    selectedCategoryIds,
    onChangeFullboxOpened,
    selectedCategoryNames,
}) => {
    return <>
        {isFullboxOpened
            ? <FullBox
                title='Nehnuteľnosť'
                onBoxToggle={() => onChangeFullboxOpened(!isFullboxOpened)}
                content={(
                    <CompleteBox
                        options={categoryOptions}
                        onChangeCategory={(categoryId) => {
                            const isAlreadySelected = selectedCategoryIds.includes(
                                categoryId
                            );

                            return isAlreadySelected
                                ? onDeselectCategory(categoryId)
                                : onSelectCategory(categoryId);
                        }}
                        selectedCategoryIds={selectedCategoryIds}
                    />
                )}
            />
            : <BaseBox
                title='Nehnuteľnosť'
                text={selectedCategoryNames}
                placeholder='Všetky kategórie'
                onBoxToggle={() => onChangeFullboxOpened(!isFullboxOpened)}
            />
        }
    </>;
};