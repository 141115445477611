import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';

interface IFullBox {
    title: string;
    content: ReactNode;
    onBoxToggle: () => void;
}

export const FullBox: FC<IFullBox> = ({ title, content, onBoxToggle }) => {

    return <Box
        paddingX={3}
        paddingY={3}
        display='flex'
        borderRadius='16px'
        alignItems='flex-start'
        flexDirection='column'
        justifyContent='space-between'
        boxShadow='0px 2px 4px 0px rgba(0, 0, 0, 0.08)'
        bgcolor={(theme) => theme.palette.backgroundPrimary.main}
    >
        <Box mb={2} onClick={onBoxToggle}>
            <Text
                semibold
                variant='h5'
                color={(palette) => palette.labelPrimary}
            >
                {title}
            </Text>
        </Box>

        <Box width='100%'>
            {content}
        </Box>
    </Box>;
};