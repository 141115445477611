import { FC } from 'react';
import { Drawer } from '@mui/material';
import {
    Header
} from 'components/advertisementsFilter/components/common/mobileFilter/partials/filterDialog/partials/header/Header';
import {
    Content
} from 'components/advertisementsFilter/components/common/mobileFilter/partials/filterDialog/partials/content/Content';
import {
    Footer
} from 'components/advertisementsFilter/components/common/mobileFilter/partials/filterDialog/partials/footer/Footer';
import {
    ITransaction
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/transaction/ITransaction';
import {
    ICategories
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/category/ICategories';
import {
    IAutocompleteOption
} from 'components/advertisementsFilter/interfaces/location/option/IAutoCompleteOption';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';

interface IFilterDialog {
    totalCount: number;
    locationQuery: string;
    isFilterOpened: boolean;
    selectedLocation: ILocation;
    categoryOptions: ICategories;
    selectedCategoryIds: number[];
    isFetchingTotalCount: boolean;
    selectedCategoryNames: string;
    onRedirectByFilter: () => void;
    onSearchFilterReset: () => void;
    selectedSubcategoryNames: string;
    selectedTransaction: ITransaction;
    transactionOptions: ITransaction[];
    locationOptionsInitial: ILocation[];
    toggleFilter: (flag: boolean) => void;
    onSelectCategory: (value: number) => void;
    onDeselectCategory: (value: number) => void;
    onChangeTransaction: (value: number) => void;
    onChangeLocationQuery: (value: string) => void;
    onChangeLocation: (location: ILocation) => void;
    autocompleteLocationOptions: IAutocompleteOption[];
}

export const FilterDialog: FC<IFilterDialog> = ({
    totalCount,
    toggleFilter,
    locationQuery,
    isFilterOpened,
    categoryOptions,
    onSelectCategory,
    onChangeLocation,
    selectedLocation,
    onDeselectCategory,
    transactionOptions,
    onRedirectByFilter,
    selectedTransaction,
    selectedCategoryIds,
    onChangeTransaction,
    onSearchFilterReset,
    isFetchingTotalCount,
    onChangeLocationQuery,
    selectedCategoryNames,
    locationOptionsInitial,
    selectedSubcategoryNames,
    autocompleteLocationOptions,
}) => {

    return <Drawer
        anchor='bottom'
        open={isFilterOpened}
        sx={(theme) => ({
            '& .MuiBackdrop-root': {
                backgroundColor: 'inherit',
            },
            '& .MuiDrawer-paper': {
                width: '100%',
                borderRadius: 0,
                boxShadow: 'none',
                overflowY: 'hidden',
                height: '100%',
                background: theme.palette.backgroundSecondary.main,
            }
        })}
    >
        <Header
            toggleFilter={toggleFilter}
            isFilterOpened={isFilterOpened}
        />

        <Content
            locationQuery={locationQuery}
            categoryOptions={categoryOptions}
            onSelectCategory={onSelectCategory}
            onChangeLocation={onChangeLocation}
            selectedLocation={selectedLocation}
            onDeselectCategory={onDeselectCategory}
            transactionOptions={transactionOptions}
            selectedTransaction={selectedTransaction}
            selectedCategoryIds={selectedCategoryIds}
            onChangeTransaction={onChangeTransaction}
            onChangeLocationQuery={onChangeLocationQuery}
            selectedCategoryNames={selectedCategoryNames}
            locationOptionsInitial={locationOptionsInitial}
            selectedSubcategoryNames={selectedSubcategoryNames}
            autocompleteLocationOptions={autocompleteLocationOptions}
        />

        <Footer
            totalCount={totalCount}
            onButtonClick={() => {
                onRedirectByFilter();
                toggleFilter(!isFilterOpened);
            }}
            isFetchingTotalCount={isFetchingTotalCount}
            onSearchFilterReset={onSearchFilterReset}
        />
    </Drawer>;
};