import { FC } from 'react';
import { Drawer, Box } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import { Text } from 'modules/theme/components/text/Text';
import { Input } from 'modules/theme/components/fields/input/Input';
import CloseIcon from 'modules/theme/components/icons/basic/Close.svg';
import SearchIcon from 'modules/theme/components/icons/basic/Search.svg';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';
import {
    Autocomplete
} from 'components/advertisementsFilter/components/common/common/location/partials/autocomplete/Autocomplete';
import {
    AppBar
} from 'components/advertisementsFilter/components/common/mobileFilter/partials/filterDialog/partials/common/appBar/AppBar';
import {
    IAutocompleteOption
} from 'components/advertisementsFilter/interfaces/location/option/IAutoCompleteOption';

interface ILocationFullscreen {
    locationQuery: string;
    isWithParents?: boolean;
    isFullscreenOpened: boolean;
    onFullscreenToggle: (flag: boolean) => void;
    onChangeLocationQuery: (value: string) => void;
    onChangeLocation: (location: ILocation) => void;
    autocompleteLocationOptions: IAutocompleteOption[];
}

export const LocationFullscreen: FC<ILocationFullscreen> = ({
    isWithParents,
    locationQuery,
    onChangeLocation,
    isFullscreenOpened,
    onFullscreenToggle,
    onChangeLocationQuery,
    autocompleteLocationOptions,
}) => {

    return <Drawer
        anchor='bottom'
        open={isFullscreenOpened}
        sx={(theme) => ({
            '& .MuiBackdrop-root': {
                backgroundColor: 'inherit',
            },
            '& .MuiDrawer-paper': {
                width: '100%',
                borderRadius: 0,
                boxShadow: 'none',
                overflowY: 'hidden',
                height: '100%',
                background: theme.palette.backgroundPrimary.main,
            }
        })}
    >
        <AppBar
            content={(
                <Box
                    width='100%'
                    display='flex'
                    alignItems='center'
                    position='relative'
                >
                    <Svg
                        icon
                        width={24}
                        component={CloseIcon}
                        onIconClick={() => onFullscreenToggle(!isFullscreenOpened)}
                    />

                    <Box
                        left='50%'
                        position='absolute'
                        sx={{ transform: 'translate(-50%, 0)' }}
                    >
                        <Text variant='body2' semibold>
                            Lokalita
                        </Text>
                    </Box>
                </Box>
            )}
        />

        <Box padding={3}>
            <Input
                autoFocus
                value={locationQuery}
                startIcon={SearchIcon}
                placeholder='Kde hľadáte?'
                endIcon={locationQuery && (() => <Svg
                    icon
                    width={14}
                    component={CloseIcon}
                    onIconClick={() => {
                        onChangeLocationQuery('');
                        onChangeLocation(null);
                    }}
                />)}
                onChange={onChangeLocationQuery}
            />

            <Autocomplete
                isWithParents={isWithParents}
                onChangeLocation={onChangeLocation}
                options={autocompleteLocationOptions}
            />
        </Box>
    </Drawer>;
};