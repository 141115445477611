import { FC } from 'react';
import { Box } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import { Button } from 'modules/theme/components/button/Button';
import SearchIcon from 'modules/theme/components/icons/basic/Search.svg';

interface IFooter {
    totalCount: number;
    onButtonClick: () => void;
    isFetchingTotalCount: boolean;
    onSearchFilterReset: () => void;
}

export const Footer: FC<IFooter> = ({
    onButtonClick,
    onSearchFilterReset,
}) => {
    return <Box
        gap={1}
        left={0}
        bottom={0}
        paddingX={2}
        width='100%'
        paddingY={1.5}
        display='flex'
        position='absolute'
        alignItems='center'
        justifyContent='space-between'
        bgcolor={(theme) => theme.palette.backgroundPrimary.main}
        borderTop={(theme) => `1px solid ${theme.palette.dividerTransparent}`}
    >
        <Box onClick={onSearchFilterReset}>
            <Text variant='body2' underLine>Resetovať</Text>
        </Box>

        <Box width={128}>
            <Button
                fullWidth
                text={'Hľadať'}
                startIcon={SearchIcon}
                onClick={onButtonClick}
            />
        </Box>
    </Box>;
};
