import { FC } from 'react';
import {
    BaseBox
} from 'components/advertisementsFilter/components/common/mobileFilter/partials/filterDialog/partials/content/partials/boxes/baseBox/BaseBox';
import {
    FullBox
} from 'components/advertisementsFilter/components/common/mobileFilter/partials/filterDialog/partials/content/partials/boxes/fullBox/FullBox';
import {
    LocationContent
} from 'components/advertisementsFilter/components/common/mobileFilter/partials/filterDialog/partials/content/partials/location/partials/content/Content';
import { ILocation as ILocationOption } from 'modules/api/clients/location/interfaces/location/ILocation';
import {
    IAutocompleteOption
} from 'components/advertisementsFilter/interfaces/location/option/IAutoCompleteOption';

interface ILocation {
    locationQuery: string;
    isFullboxOpened: boolean;
    selectedLocation: ILocationOption;
    optionsInitial: ILocationOption[];
    onChangeFullboxOpened: (flag: boolean) => void;
    onChangeLocationQuery: (value: string) => void;
    autocompleteLocationOptions: IAutocompleteOption[];
    onChangeLocation: (location: ILocationOption) => void;
}

export const Location: FC<ILocation> = ({
    locationQuery,
    optionsInitial,
    isFullboxOpened,
    selectedLocation,
    onChangeLocation,
    onChangeFullboxOpened,
    onChangeLocationQuery,
    autocompleteLocationOptions,
}) => {
    return <>
        {isFullboxOpened
            ? <FullBox
                title='Lokalita'
                onBoxToggle={() => onChangeFullboxOpened(!isFullboxOpened)}
                content={(
                    <LocationContent
                        locationQuery={locationQuery}
                        optionsInitial={optionsInitial}
                        onChangeLocation={(location) => {
                            onChangeLocation(location);

                            if (location) onChangeFullboxOpened(!isFullboxOpened);
                        }}
                        selectedLocation={selectedLocation}
                        onChangeLocationQuery={onChangeLocationQuery}
                        autocompleteLocationOptions={autocompleteLocationOptions}
                    />
                )}
            />
            : <BaseBox
                title='Lokalita'
                placeholder='Kde hľadáte?'
                text={selectedLocation && selectedLocation.name}
                onBoxToggle={() => onChangeFullboxOpened(!isFullboxOpened)}
            />
        }
    </>;
};